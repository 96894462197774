<template>
  <div class="app-post-footer">
    <div class="app-post-footer__main">
      <div class="app-post-footer__like">
        <button
          type="button"
          class="button"
          @click.prevent="addLike"
          :disabled="!hasAccess"
        >
          <CIcon
            name="like"
            path="post"
            class="app-post-footer__icon app-post-footer__icon--like"
            :class="{
              'app-post-footer__icon--like--active': likeObject.isLike,
            }"
          />
        </button>
      </div>

      <CLink
        class="app-post-footer__chat"
        :class="{ 'app-post-footer__chat--disabled': !allowComments }"
        :to="commentsUrl"
      >
        <CIcon
          name="chat"
          path="post"
          class="app-post-footer__icon app-post-footer__icon--chat"
        />
      </CLink>

      <button
        type="button"
        class="app-post-footer__tip"
        :class="{ 'app-post-footer__tip--disabled': isOwner || !allowTips }"
        @click.stop="tip"
      >
        <CIcon
          name="tip"
          path="post"
          class="app-post-footer__icon app-post-footer__icon--tip"
        />
      </button>

      <button
        type="button"
        class="app-post-footer__bookmark"
        @click.prevent="addBookmark(post)"
      >
        <CIcon
          name="bookmark"
          path="post"
          class="app-post-footer__icon app-post-footer__icon--bookmark"
          :class="{
            'app-post-footer__icon--bookmark--active':
              bookmarkObject.isBookmark,
          }"
        />
      </button>
    </div>

    <div class="app-post-footer__addition">
      <span class="app-post-footer__font app-post-footer__font--addition">
        {{
          $tc("general.x-likes", likeObject.likesCount , {
            count: likeObject.likesCount,
          })
        }}
      </span>

      <span class="app-post-footer__font app-post-footer__font--addition">
        {{
          $tc("general.x-comments", post.commentsCount || 0, {
            count: post.commentsCount || 0,
          })
        }}
      </span>
    </div>
  </div>
</template>
<script>
import Post from "@/components/models/Post";

import like, { likeBaseUrls } from "@/mixins/like";
import bookmark from "@/mixins/bookmark";

import CIcon from "@/features/ui/CIcon.vue";
import CLink from "@/features/ui/CLink.vue";
import Payment from "@/components/models/Payment";
import {mapMutations} from "vuex";
import anonymousHandler from "@/mixins/anonymousHandler";

export default {
  name: "AppPostFooter",
  components: { CLink, CIcon },
  mixins: [like, bookmark, anonymousHandler],
  props: {
    post: {
      type: Post,
      required: true,
    },
  },

  computed: {
    activeUser() {
      return this.$store.state.currentUser;
    },

    publisher() {
      return this.post?.user;
    },

    hasAccess() {
      return this.activeUser.isAdmin || this.post.hasAccess;
    },

    commentsUrl() {
      if (!this.allowComments) return "";

      return this.post.url;
    },

    isOwner() {
      return (
        this.activeUser.isAdmin || this.publisher?.id === this.activeUser.id
      );
    },

    allowTips() {
      return this.post.allowTips;
    },

    allowComments() {
      return this.post.allowComments;
    },
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setTempTip: "payments/setTempTip"
    }),
    initState() {
      const { likesCount, isLiked, id, isBookmarked } = this.post;
      this.setLikeData({
        baseRoute: likeBaseUrls.posts,
        id: id,
        isLike: isLiked,
        likesCount,
      });

      this.setBookmarkData({ isBookmark: isBookmarked });
    },

    tip() {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }

      this.setTempTip({ ...this.post, tipType: Payment.TYPE_TIP })
      this.$popup.open("TipPopup");
    },
  },
};
</script>

<style lang="scss" scoped>
.app-post-footer {
  &__main {
    width: 100%;
    display: flex;
    align-items: center;
    padding: em(8) em(18) 0;
  }

  &__addition {
    padding: em(10) em(17) 0;
  }

  &__like {
    display: flex;
    margin-right: em(15);
  }

  &__chat {
    display: flex;
    width: auto;
    margin-right: em(15);

    &--disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  &__tip {
    display: flex;
    width: auto;

    &--disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  &__bookmark {
    display: flex;
    width: auto;
    margin-left: auto;
  }

  &__font {
    color: $app-black;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &--addition {
      color: $black;
      font-size: em(12);
      font-weight: 500;

      &:not(:first-child) {
        &:before {
          color: $black;
          content: " · ";
          font-size: em(12);
          position: relative;
        }
      }
    }
  }

  &__icon {
    color: $app-gray-7;

    &--chat {
      width: em(18);
      height: em(18);
    }

    &--tip {
      width: em(20);
      height: em(20);
      margin-top: em(2);
    }

    &--like {
      width: em(20);
      height: em(18);
      transition: color $time-normal $ease, fill $time-normal $ease;

      &--active {
        color: $app-red;
        fill: $app-red;
      }
    }

    &--bookmark {
      width: em(15);
      height: em(19);
      transition: color $time-normal $ease, fill $time-normal $ease;

      &--active {
        color: $app-blue;
        fill: $app-blue;
      }
    }
  }
}
</style>

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import { mapMutations } from "vuex";
import anonymousHandler from "@/mixins/anonymousHandler";

export default {
  mixins: [waitRequest, validationError, anonymousHandler],
  data() {
    return {
      bookmarkObject: {
        isBookmark: false,
      },
    };
  },
  methods: {
    ...mapMutations({
      updateBookmarks: "bookmarks/updateBookmarks",
      removeBookmark: "bookmarks/removeBookmark",
    }),
    setBookmarkData({ isBookmark }) {
      this.bookmarkObject.isBookmark = isBookmark;
    },

    addBookmark(post) {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }

      if (this.requestInProgress) return;

      this.toggleBookmark(post);

      return this.waitRequest(() => {
        this.$hideSpinner();
        return this.$post(
          "/bookmarks/" + this.post.id,
          {},
          this.successBookmarkUpdate,
          (err) => this.failureBookmarkUpdate(err, post),
          this.checkErrors
        );
      });
    },

    updateBookmark({ isBookmark = true } = {}) {
      this.bookmarkObject.isBookmark = isBookmark;
    },

    successBookmarkUpdate(data) {
      this.bookmarkObject.isBookmark = data?.is_bookmarked;
    },

    failureBookmarkUpdate(err, post) {
      console.log(err);
      this.toggleBookmark(post);
    },

    toggleBookmark(post) {
      if (this.bookmarkObject.isBookmark) {
        // remove like
        this.updateBookmark({ isBookmark: false });
        this.removeBookmark({ bookmarks: post });
      } else {
        this.updateBookmark({ isBookmark: true });
        this.updateBookmarks({ bookmarks: [post] });
      }
    },
  },
};

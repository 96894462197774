<template>
  <div class="app-post-content">
    <AppPostPoll
      v-if="poll.length"
      :poll="poll"
      :post="post"
      :has-voted="currentPost.hasVoted"
      @vote="vote"
    />

    <div class="media" v-if="media.length">
      <CSwiper
        v-if="media.length"
        :items="media"
        :slider-config="sliderConfig"
        class="app-post-cont__swiper"
        has-pagination
      >
        <template #slide="{ itemData: item } = {}">
          <AppPostContentCard :media="item" />
        </template>
      </CSwiper>
    </div>
  </div>
</template>
<script>
import Post from "@/components/models/Post";
import AppPostContentCard from "@/features/containers/post/components/AppPostContentCard.vue";
import CSwiper from "@/features/ui/CSwiper.vue";
import AppPostPoll from "@/features/containers/post/components/AppPostPoll.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

const flowSwiperConfig = {
  slidesPerView: 1,
  speed: 300,
  navigation: false,
  centeredSlides: true,
  observer: true,
};

export default {
  name: "AppPostContent",
  components: { AppPostPoll, CSwiper, AppPostContentCard },
  mixins: [waitRequest, validationError],
  props: {
    post: {
      type: Post,
      required: true,
    },
  },

  data() {
    return {
      sliderConfig: flowSwiperConfig,
      currentPost: this.post
    };
  },

  computed: {
    media() {
      return this.post.media;
    },

    slides() {
      return this.media;
    },

    poll() {
      return this.currentPost?.poll;
    },

    hasAccess() {
      return this.$store.state.currentUser.isAdmin || this.currentPost.hasAccess;
    },
  },

  methods: {
    vote(option) {
      // TODO: add popup about was already voted
      if (this.currentPost.hasVoted || this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
          "/posts/" + this.currentPost.id + "/" + option.id + "/vote",
          {},
          (data) => {
            this.currentPost = new Post(data);
          },
          (errors) => {
            console.log(errors);
          },
          this.checkErrors
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-post-cont {
  &__swiper {
    width: 100%;
  }
}

.media {
  width: 100%;
}

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-post-footer"},[_c('div',{staticClass:"app-post-footer__main"},[_c('div',{staticClass:"app-post-footer__like"},[_c('button',{staticClass:"button",attrs:{"type":"button","disabled":!_vm.hasAccess},on:{"click":function($event){$event.preventDefault();return _vm.addLike.apply(null, arguments)}}},[_c('CIcon',{staticClass:"app-post-footer__icon app-post-footer__icon--like",class:{
            'app-post-footer__icon--like--active': _vm.likeObject.isLike,
          },attrs:{"name":"like","path":"post"}})],1)]),_vm._v(" "),_c('CLink',{staticClass:"app-post-footer__chat",class:{ 'app-post-footer__chat--disabled': !_vm.allowComments },attrs:{"to":_vm.commentsUrl}},[_c('CIcon',{staticClass:"app-post-footer__icon app-post-footer__icon--chat",attrs:{"name":"chat","path":"post"}})],1),_vm._v(" "),_c('button',{staticClass:"app-post-footer__tip",class:{ 'app-post-footer__tip--disabled': _vm.isOwner || !_vm.allowTips },attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.tip.apply(null, arguments)}}},[_c('CIcon',{staticClass:"app-post-footer__icon app-post-footer__icon--tip",attrs:{"name":"tip","path":"post"}})],1),_vm._v(" "),_c('button',{staticClass:"app-post-footer__bookmark",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addBookmark(_vm.post)}}},[_c('CIcon',{staticClass:"app-post-footer__icon app-post-footer__icon--bookmark",class:{
          'app-post-footer__icon--bookmark--active':
            _vm.bookmarkObject.isBookmark,
        },attrs:{"name":"bookmark","path":"post"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"app-post-footer__addition"},[_c('span',{staticClass:"app-post-footer__font app-post-footer__font--addition"},[_vm._v("\n      "+_vm._s(_vm.$tc("general.x-likes", _vm.likeObject.likesCount , {
          count: _vm.likeObject.likesCount,
        }))+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"app-post-footer__font app-post-footer__font--addition"},[_vm._v("\n      "+_vm._s(_vm.$tc("general.x-comments", _vm.post.commentsCount || 0, {
          count: _vm.post.commentsCount || 0,
        }))+"\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
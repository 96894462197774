<template>
  <div class="app-post-message" v-if="post.message">
    <span class="app-post-message__font app-post-message__font--base">
      {{ currentText.base }}
      <b-collapse
        :id="`collapse-show-more-${post.id}`"
        class="app-post-message__collapse"
        v-model="visible"
      >
        <span class="app-post-message__font app-post-message__font--base">
          {{ currentText.addition }}
        </span>
      </b-collapse>

      <button
        v-if="currentText.addition"
        type="button"
        v-b-toggle="`collapse-show-more-${post.id}`"
        class="button app-post-message__button"
      >
        <span>{{ !visible ? "..." : "" }}</span>

        <span class="app-post-message__font app-post-message__font--more">
          {{ !visible ? "more" : "less" }}
        </span>
      </button>
    </span>
  </div>
</template>
<script>
export default {
  name: "AppPostMessage",
  props: {
    post: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  computed: {
    currentText() {
      const message = this.post?.message;
      const baseMessageMaxLength = 80;
      const isLongMessage = message.length >= baseMessageMaxLength;
      const messageBase = isLongMessage ? message.slice(0, 80) : message;
      const messageAddition = isLongMessage ? message.slice(80) : "";

      return { base: messageBase, addition: messageAddition };
    },
  },

  methods: {
    goToPost() {
      this.$router.push(`/${this.post.id}/${this.post?.user?.username}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-post-message {
  position: relative;
  margin-top: em(6);

  &__collapse {
    display: inline;
  }

  &__button {
    display: inline;
    width: auto;
  }

  &__font {
    font-family: $font-default;
    font-style: normal;
    line-height: normal;
    color: $black;

    &--base {
      font-size: 14px;
      color: $app-black;
      font-weight: 400;
      word-break: break-word;
    }

    &--more {
      font-weight: 600;
      color: $app-blue;
      font-size: em(14);
    }
  }
}
</style>

<template>
  <b-card no-body no-footer class="poll" v-if="optionsList.length">
    <b-list-group flush>
      <b-list-group-item
        v-for="(options, index) in optionsList"
        :key="index"
        href="#"
        @click.prevent="vote(options)"
        class="item"
      >
        <div class="poll__row  title d-flex align-items-center justify-content-between">
          <div class="poll__font poll__font--title">{{ options.option }}</div>
          <div class="poll__font poll__font--title" v-if="options.votes_count">
            {{ options.votes_count }}
          </div>
        </div>

        <div class="poll__percent-row p-1">
          <div
            :style="{ width: calcPercent(options) + '%' }"
            class="poll__percent"
          />
        </div>
      </b-list-group-item>
    </b-list-group>

    <b-card-footer class="border-top-0">
      <span class="poll__font poll__font--footer">
        <span class="poll__icon-wrapper">
          <CIcon name="poll" path="post" class="poll__icon poll__icon--poll" />
        </span>

        <span class="poll__font poll-font--vote">
          {{ poll.title || "Poll" }}
        </span>

        <span class="poll__font poll__font--vote">
          {{ $tc("general.x-votes", [totalVotes]) }}.
          {{ isExpires ? expired : "" }}
        </span>
      </span>
    </b-card-footer>
  </b-card>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";
import dayjs from "dayjs";
import { timeUntilExpiration } from "@/tools/helpers";
import { expirationKeys } from "@/config/expirationKeys";

export default {
  name: "AppPostPoll",
  components: { CIcon },

  props: {
    post: {
      type: Object,
      required: true,
    },

    poll: {
      type: Array,
      required: true,
    },

    hasVoted: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    optionsList() {
      return this.poll;
    },

    totalVotes() {
      return this.poll.reduce((acc, item) => item.votes_count + acc, 0);
    },

    isExpires() {
      return Boolean(this.post.expires);
    },

    expiration() {
      const startDate = this.post.created_at;
      const currentDate = dayjs();
      const durationDays = this.post.expires;
      const endDate = startDate.add(durationDays, "day");

      return timeUntilExpiration(currentDate, endDate);
    },

    isCanVote() {
      return this.expiration.key !== expirationKeys.EXPIRED || !this.isExpires;
    },

    expired() {
      return this.$t(
        `general.expirationDate.options.${this.expiration.key}`,
        this.expiration.params
      );
    },
  },
  methods: {
    calcPercent(question) {
      return Math.round((question.votes_count / this.totalVotes) * 100) || 0;
    },

    vote(options) {
      if (!this.isCanVote) return;

      this.$emit("vote", options);
    },
  },
};
</script>

<style lang="scss" scoped>
.poll {
  margin: em(16);

  &__percent-row {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__row {
    position: relative;
    z-index: 1;
  }

  &__percent {
    border-radius: em(5);
    background: #d7e6ff;
    height: 100%;
  }

  &__icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: em(16, 14);
    height: em(16, 14);
    margin-right: em(6, 14);
  }

  &__icon {
    &--poll {
      width: em(14, 14);
      height: em(14, 14);
    }
  }

  &__font {
    color: #969ea8;
    font-family: $font-default;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--title {
      color: #969ea8;
      font-size: em(14);
      font-weight: 500;
    }

    &--footer {
      color: #9f9f9f;
      font-size: em(14);
      font-weight: 400;
      display: inline-flex;
      align-items: center;
    }

    &--vote {
      color: #9f9f9f;

      &:not(:first-child) {
        &:before {
          color: inherit;
          content: " · ";
          font-size: inherit;
          position: relative;
          margin-left: em(5, 14);
        }
      }
    }
  }
}
</style>

<template>
  <div class="post">
    <AppPostHeader
      :user="post.user"
      :post="post"
      :published="published"
      class="post__header"
    />

    <AppPostMessage :post="post" class="post__text" />
    <AppPostContent :post="post" class="post__content" v-if="hasAccess" />
    <AppPostLock :post="post" class="post__content" v-else />

    <AppPostFooter :post="post" />
  </div>
</template>

<script>
import Post from "@/components/models/Post";

import AppPostHeader from "@/features/containers/post/components/AppPostHeader.vue";
import AppPostMessage from "@/features/containers/post/components/AppPostMessage.vue";
import AppPostFooter from "@/features/containers/post/components/AppPostFooter.vue";
import AppPostLock from "@/features/containers/post/components/AppPostLock.vue";
import AppPostContent from "@/features/containers/post/components/AppPostContent.vue";
export default {
  name: "AppPost",
  components: {
    AppPostContent,
    AppPostFooter,
    AppPostMessage,
    AppPostHeader,
    AppPostLock,
  },
  props: {
    post: Post,
  },
  computed: {
    hasAccess() {
      return this.$store.state.currentUser.isAdmin || this.post.hasAccess;
    },

    published() {
      return this.post.ago || "";
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";
.post {
  width: 100%;
  margin-bottom: em(38);

  &__header {
  }

  &__text {
    padding: em(17) em(14) em(12);
  }

  &__content {
  }
}
</style>

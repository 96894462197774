<template>
  <div class="app-post-lock">
    <b-aspect aspect="4:3">
      <div class="app-post-lock__content">
        <div class="app-post-lock__wrapper">
          <div class="app-post-lock__main">
            <CIcon
              name="lock"
              path="post"
              class="app-post-lock__icon app-post-lock__icon--lock"
            />

            <div class="app-post-lock__stat">
              <div class="app-post-lock__counter" v-if="post.images">
                <span class="app-post-lock__icon-wrapper">
                  <CIcon
                    name="image"
                    path="post"
                    class="app-post-lock__icon app-post-lock__icon--image"
                  />
                </span>

                <span class="app-post-lock__font app-post-lock__font--counter">
                  {{ post.images }}
                </span>
              </div>

              <div class="app-post-lock__counter" v-if="post.videos">
                <span class="app-post-lock__icon-wrapper">
                  <CIcon
                    name="video"
                    path="post"
                    class="app-post-lock__icon app-post-lock__icon--video"
                  />
                </span>

                <span class="app-post-lock__font app-post-lock__font--counter">
                  {{ post.videos }}
                </span>
              </div>
            </div>

            <CButton
              @click.prevent="buttonConfig.action"
              class="app-post-lock__button"
            >
              {{ buttonConfig.text }}
            </CButton>
          </div>
        </div>
      </div>
    </b-aspect>
  </div>
</template>
<script>
import CButton from "@/features/ui/СButton.vue";

import Post from "@/components/models/Post";
// import Payment from "@/components/models/Payment";
import CIcon from "@/features/ui/CIcon.vue";
import { mapActions, mapMutations } from "vuex";
import userPosts from "@/mixins/userPosts";
import anonymousHandler from "@/mixins/anonymousHandler";

export default {
  name: "PostLock",
  components: {
    CIcon,
    CButton,
  },
  mixins: [userPosts, anonymousHandler],
  props: {
    post: {
      type: Post,
      required: true,
    },
  },

  computed: {
    userId() {
      return this.post?.user?.id;
    },

    buttonConfig() {
      if(this.post.access_type === Post.ACCESS_TYPE_PAID_POST) {
        return {
          text: this.$t(`general.lockedPost.unlockFor`, { price: this.post?.price || 0}),
          action: this.unlock,
        };
      }

      return {
        text: this.$t(`general.lockedPost.subscribe`),
        action: this.unlock,
      };
    },
  },

  methods: {
    ...mapMutations({
      setCurrentSubscription: "subscriptions/setCurrentSubscription",
      updatePost: "postHome/updatePost",
    }),
    ...mapActions({
      fetchPost: "postHome/fetchPost",
    }),
    unlock() {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }

      const postContext = this.$route.name;
      const isIndexPage = postContext === "index";
      const isUserPage = postContext === "user";
      const postData = {
        ...this.post,
        sub: this.post.user,
      };

      if (isIndexPage) {
        this.setCurrentSubscription({
          ...postData,
          action: async () => {
            const response = await this.fetchPost(this.post.id).catch(
              this.checkErrors
            );
            this.updatePost({
              post: response.data,
            });
          },
        });
      }

      if (isUserPage) {
        this.setCurrentSubscription({
          ...postData,
          action: () => {
            this.initialLoadPost();
          },
        });
      }

      this.$popup.open("SubscriptionPopup");
    },
  },
};
</script>

<style lang="scss" scoped>
.app-post-lock {
  position: relative;
  border-top: 1px solid $app-gray-9;
  border-bottom: 1px solid $app-gray-9;
  background-color: $app-gray-8;

  &__content {
    display: flex;
    height: 100%;
  }

  &__stat {
    display: flex;
    justify-content: center;
    margin: em(8);
  }

  &__counter {
    margin-top: em(23);
    padding: em(7) em(9);
    border-radius: em(10);
    border: em(2) solid $app-gray-10;
    background: $white;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-left: em(6);
    }
  }

  &__wrapper {
    width: 100%;
  }

  &__main {
    display: grid;
    align-items: end;
    height: 100%;
    grid-template-rows: 1fr auto;
  }

  &__button {
    padding: em(23) em(20) em(49);
  }

  &__icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: em(17, 14);
    height: em(17, 14);
    margin-right: em(6, 14);
  }

  &__icon {
    color: $app-font-color-gray-2;

    &--lock {
      width: em(57);
      height: em(60);
      margin: auto auto 0;
    }

    &--image {
      width: em(15, 14);
      height: em(15, 14);
    }

    &--video {
      width: em(15, 14);
      height: em(16, 14);
    }
  }

  &__font {
    &--counter {
      color: $app-font-color-gray-2;
      font-family: $font-default;
      font-size: em(13);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
